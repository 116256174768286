import React from "react";

import { Link, useParams } from "react-router-dom";
import {
  getDocDataSnap,
  setDocData,
  storage,
} from "../../utils/firebase.utils";
import { useState, useEffect } from "react";
import InputBox from "../../components/InputBox";
import Button from "../../components/Button";
import EmploymentHistory from "./EmploymentHistory";
import FamilyBackground from "./FamilyBackground";
import Attachments from "./Attachments";
import NoImage from "../../assets/noimage.jpeg";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

function EmployeeDetails() {
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [activeLink, setActiveLink] = useState("Employee Information");
  const [progress, setProgress] = useState(0);

  const links = [
    "Employee Information",
    "Employment History",
    "Family Background",
    "Attachments",
  ];

  useEffect(() => {
    const fetchData = async () => {
      const res = await getDocDataSnap("employees", id, (data) => {
        setFormData(data);
        setIsLoading(false);
      });
    };
    fetchData();
  }, []);

  const { id } = useParams();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleEditMode = (e) => {
    e.preventDefault();
    setEditMode(!editMode);
    if (editMode) {
      setDocData("employees", id, formData);
    }
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `images/${id}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
        setProgress(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFormData({ ...formData, image: downloadURL });
        });
      }
    );
  };

  return (
    <div className="px-4 py-2 relative text-xs min-h-screen">
      <Link to="/employees" className="text-leechiuBlue underline">
        Back to Employee Masterlist
      </Link>
      <div className=" font-bold text-xl text-leechiuBlue mt-8">
        Employee Details
      </div>

      <div>
        <img
          src={formData.image || NoImage}
          className="w-60 aspect-square object-contain"
          alt=""
        />
        {editMode && <input type="file" onChange={handleUpload} />}
        {progress > 0 && <div>Uploading {progress}%</div>}
      </div>

      <div className="font-bold uppercase text-leechiuOrange">
        {formData.lastName}, {formData.firstName}, {formData.middleName}
      </div>

      <div className="flex mt-2 items-center justify-start  text-left w-fit rounded-xl border border-leechiuOrange divide-leechiuOrange divide-x mb-4 cursor-pointer overflow-hidden">
        {links.map((link, index) => (
          <div
            key={index}
            onClick={() => setActiveLink(link)}
            className={`p-2 font-bold ${
              activeLink === link
                ? "bg-leechiuOrange text-white"
                : "text-zinc-500"
            }`}
          >
            {link}
          </div>
        ))}
      </div>

      {/* employee information */}
      {activeLink === "Employee Information" && (
        <form className="bg-white p-2 rounded-xl shadow-lg flex items-start justify-between gap-4 ">
          <div className="w-1/2 space-y-2">
            <div className="text-left mb-4">
              <Button
                label={editMode ? "Save" : "Edit"}
                onClick={handleEditMode}
              />
            </div>

            <div className="text-left font-bold text-leechiuBlue">
              Basic Employee Information
            </div>

            <div className="flex items-center justify-start gap-2 flex-wrap">
              <InputBox
                label="First Name"
                type="text"
                placeholder="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                disabled={!editMode}
              />
              <InputBox
                label="Middle Name"
                name="middleName"
                type="text"
                placeholder="Middle Name"
                value={formData.middleName}
                onChange={handleChange}
                disabled={!editMode}
              />
              <InputBox
                label="Last Name"
                name="lastName"
                type="text"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                disabled={!editMode}
              />
              <InputBox
                label="Sufix"
                name="sufix"
                type="text"
                placeholder="Sufix"
                value={formData.sufix}
                onChange={handleChange}
                disabled={!editMode}
              />
            </div>
            <div className="flex items-center justify-start gap-2 flex-wrap">
              <InputBox
                label="Email Address"
                name="email"
                type="email"
                placeholder="Email Address"
                value={formData.email}
                onChange={handleChange}
                disabled={!editMode}
              />
              <InputBox
                label="Department"
                name="department"
                type="text"
                placeholder="Enter Department"
                value={formData.department}
                onChange={handleChange}
                disabled={!editMode}
              />
              <InputBox
                label="Position"
                name="position"
                type="text"
                placeholder="Position"
                value={formData.position}
                onChange={handleChange}
                disabled={!editMode}
              />

              <InputBox
                label="Start Date"
                name="startDate"
                type="date"
                placeholder="Start Date of Employment"
                value={formData.startDate}
                onChange={handleChange}
                disabled={!editMode}
              />

              <InputBox
                label="End Date"
                name="endDate"
                type="date"
                placeholder="End Date of Employment"
                value={formData.endDate}
                onChange={handleChange}
                disabled={!editMode}
              />
            </div>
            <div>
              <div className="text-left font-bold text-leechiuBlue">
                Payroll Information
              </div>

              <div className="flex items-center justify-start gap-2 flex-wrap mt-2">
                <InputBox
                  label="Employee Number"
                  type="text"
                  placeholder="Employee Number"
                  name="employeeNumber"
                  value={formData.employeeNumber}
                  onChange={handleChange}
                  disabled={!editMode}
                />

                <InputBox
                  label="Employee Status"
                  type="text"
                  placeholder="Employee Status"
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  disabled={!editMode}
                />
                <InputBox
                  type={editMode ? "number" : "text"}
                  label="Basic Salary"
                  placeholder="Enter Basic Salary"
                  name="basicSalary"
                  value={
                    editMode
                      ? formData.basicSalary
                      : parseFloat(formData.basicSalary).toLocaleString()
                  }
                  onChange={handleChange}
                  disabled={!editMode}
                />

                <InputBox
                  label="BPI Account Number"
                  name="bankAccount"
                  type="text"
                  placeholder="Bank Account Number"
                  value={formData.bankAccount}
                  onChange={handleChange}
                  disabled={!editMode}
                />

                <InputBox
                  type={"text"}
                  label="SSS Number"
                  placeholder="Enter SSS Number"
                  name="sssNumber"
                  value={formData.sssNumber}
                  onChange={handleChange}
                  disabled={!editMode}
                />

                <InputBox
                  type={"text"}
                  label="Philhealth Number"
                  placeholder="Enter Philhealth Number"
                  name="phicNumber"
                  value={formData.phicNumber}
                  onChange={handleChange}
                  disabled={!editMode}
                />

                <InputBox
                  type={"text"}
                  label="PagIbig Number"
                  placeholder="Enter PagIbig Number"
                  name="hdmfNumber"
                  value={formData.hdmfNumber}
                  onChange={handleChange}
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="text-left font-bold text-leechiuBlue">
              Personal Information
            </div>
            <div>
              <div className="flex items-center justify-start gap-2 flex-wrap">
                <InputBox
                  type={"date"}
                  label="Birthdate"
                  placeholder="Enter Birthdate"
                  name="birthdate"
                  value={formData.birthdate}
                  onChange={handleChange}
                  disabled={!editMode}
                />
                <InputBox
                  type={"text"}
                  label="Birthplace"
                  placeholder="Enter Birthplace"
                  name="birthplace"
                  value={formData.birthplace}
                  onChange={handleChange}
                  disabled={!editMode}
                />
                <InputBox
                  type={"text"}
                  label="Civil Status"
                  placeholder="Enter Civil Status"
                  name="civilStatus"
                  value={formData.civilStatus}
                  onChange={handleChange}
                  disabled={!editMode}
                />

                <InputBox
                  type={"text"}
                  label={"Name of Spouse(if Married)"}
                  placeholder="Enter Spouse Name"
                  name="spouse"
                  value={formData.spouse}
                  onChange={handleChange}
                  disabled={!editMode}
                />

                <InputBox
                  type={"text"}
                  label="Citizenship"
                  placeholder="Enter Citizenship"
                  name="citizenship"
                  value={formData.citizenship}
                  onChange={handleChange}
                  disabled={!editMode}
                />

                <InputBox
                  label={"Current Address"}
                  placeholder={"Enter Current Address"}
                  name={"currentAddress"}
                  value={formData.currentAddress}
                  onChange={handleChange}
                  disabled={!editMode}
                />
              </div>
            </div>
          </div>

          <div className="w-1/2 flex flex-col items-start justify-start">
            <div className="text-left font-bold text-leechiuBlue">
              Educational Attainment
            </div>
            <div className="uppercase font-bold text-zinc-500 mt-2">
              Primary School
            </div>
            <div className="flex items-center justify-normal gap-2">
              <InputBox
                label={"School Name"}
                placeholder={"Enter School Name"}
                name={"primarySchool"}
                value={formData.primarySchool}
                onChange={handleChange}
                disabled={!editMode}
              />
              <InputBox
                label={"Year Graduated"}
                placeholder={"Enter Year Graduated"}
                name={"primaryGraduated"}
                value={formData.primaryGraduated}
                onChange={handleChange}
                disabled={!editMode}
              />
            </div>
            <div className="mt-2 uppercase font-bold text-zinc-500">
              Secondary School
            </div>
            <div className="flex items-center justify-normal gap-2">
              <InputBox
                label={"School Name"}
                placeholder={"Enter School Name"}
                name={"secondarySchool"}
                value={formData.secondarySchool}
                onChange={handleChange}
                disabled={!editMode}
              />
              <InputBox
                label={"Year Graduated"}
                placeholder={"Enter Year Graduated"}
                name={"secondaryGraduated"}
                value={formData.secondaryGraduated}
                onChange={handleChange}
                disabled={!editMode}
              />
            </div>
            <div className="mt-2 uppercase font-bold text-zinc-500">
              Tertiary School
            </div>
            <div className="flex items-center justify-normal gap-2 flex-wrap">
              <InputBox
                label={"School Name"}
                placeholder={"Enter School Name"}
                name={"tertiarySchool"}
                value={formData.tertiarySchool}
                onChange={handleChange}
                disabled={!editMode}
              />
              <InputBox
                label={"Year Graduated"}
                placeholder={"Enter Year Graduated"}
                name={"tertiaryGraduated"}
                value={formData.tertiaryGraduated}
                onChange={handleChange}
                disabled={!editMode}
              />
              <InputBox
                label={"Degree"}
                placeholder={"Enter Degree"}
                name={"tertiaryDegree"}
                value={formData.tertiaryDegree}
                onChange={handleChange}
                disabled={!editMode}
              />
            </div>
            <div className="mt-2 uppercase font-bold text-zinc-500">
              Post Graduate School
            </div>

            <div className="flex items-center justify-normal gap-2 flex-wrap">
              <InputBox
                label={"School Name"}
                placeholder={"Enter School Name"}
                name={"postGraduateSchool"}
                value={formData.postGraduateSchool}
                onChange={handleChange}
                disabled={!editMode}
              />
              <InputBox
                label={"Year Graduated"}
                placeholder={"Enter Year Graduated"}
                name={"postGraduateGraduated"}
                value={formData.postGraduateGraduated}
                onChange={handleChange}
                disabled={!editMode}
              />
              <InputBox
                label={"Degree"}
                placeholder={"Enter Degree"}
                name={"postGraduateDegree"}
                value={formData.postGraduateDegree}
                onChange={handleChange}
                disabled={!editMode}
              />
            </div>
          </div>
        </form>
      )}

      {/* Employment history */}
      {activeLink === "Employment History" && <EmploymentHistory />}
      {activeLink === "Family Background" && <FamilyBackground />}
      {activeLink === "Attachments" && <Attachments />}
    </div>
  );
}

export default EmployeeDetails;
