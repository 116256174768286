import React, { useEffect, useState } from "react";
import { queryAllDocsByFieldSnap } from "../../utils/firebase.utils";
import RecruitmentCard from "./RecruitmentCard";
import RecruitmentDetails from "./RecruitmentDetails";
import PageModal from "../../components/PageModal";

export default function Recruitment() {
  const [activeLink, setActiveLink] = useState("pending");
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewMode, setViewMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const fetchItems = async () => {
      await queryAllDocsByFieldSnap(
        "personnelRequests",
        "status",
        "approved",
        (data) => {
          setItems(data);
          setIsLoading(false);
        }
      );
    };

    fetchItems();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const statusItems = ["pending", "sourcing", "completed", "on-hold"];

  const handleViewItem = (selected) => {
    setViewMode(!viewMode);
    setSelectedItem(selected);

    if (viewMode) {
      setSelectedItem(null);
    }
  };

  const pendingItems = items.filter(
    (item) => item.jobPostStatus !== "published"
  );

  const sourcingItems = items.filter(
    (item) => item.jobPostStatus === "published"
  );

  return (
    <div>
      <div className="header">Recruitment</div>

      <div className="mt-4">
        <div className="links-container">
          {statusItems.map((link, index) => {
            return (
              <div
                className={`capitalize ${
                  activeLink === link ? "activeLinkStyle" : "inactiveLinkStyle"
                }`}
                key={index}
                onClick={() => setActiveLink(link)}
              >
                {link}
              </div>
            );
          })}
        </div>
      </div>

      <div className="mt-4 space-y-2">
        {activeLink === "pending" &&
          pendingItems.map((item, index) => (
            <div
              key={index}
              className="card"
              onClick={() => handleViewItem(item)}
            >
              <RecruitmentCard data={item} />
            </div>
          ))}
      </div>

      <div className="mt-4 space-y-2">
        {activeLink === "sourcing" &&
          sourcingItems.map((item, index) => (
            <div
              key={index}
              className="card"
              onClick={() => handleViewItem(item)}
            >
              <RecruitmentCard data={item} />
            </div>
          ))}
      </div>

      <div>
        {viewMode && (
          <PageModal>
            <RecruitmentDetails
              data={selectedItem}
              handleClose={handleViewItem}
            />
          </PageModal>
        )}
      </div>
    </div>
  );
}
