import React from "react";

export default function PersonnelRequestCard({ data }) {
  return (
    <div>
      <div>{data.createdAt}</div>
      <div>Requested by: {data.requestedBy}</div>
      <div>Job Title: {data.jobTitle}</div>
      <div>Description: {data.description}</div>
      <div>Budget: {data.budgetedSalary}</div>
      <div>Estimated Start Date: {data.startDate}</div>
      <div>{data.status}</div>
    </div>
  );
}
