import React, { useEffect, useState } from "react";
import { getDocDataSnap, setDocData } from "../../utils/firebase.utils";

export default function AnnouncementDetails({ data, setViewMode }) {
  const [info, setInfo] = useState(data);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchInfo = async () => {
      await getDocDataSnap("announcements", data.id, (data) => {
        setInfo(data);
      });
    };
    fetchInfo();
  }, []);

  const handlePublishStatus = async () => {
    await setDocData(
      "announcements",
      data.id,
      info.status === "Published"
        ? { status: "Unpublished" }
        : { status: "Published" }
    );
  };
  return (
    <div className="page relative">
      <button onClick={() => setViewMode(false)} className="close-button">
        Close
      </button>

      <div>
        <div className="header">Announcement Details</div>
        <button
          className={`text-white px-2 py-1 rounded-lg ${
            info.status === "Published" ? "bg-green-500" : "bg-gray-600"
          }`}
          onClick={handlePublishStatus}
        >
          {info.status}
        </button>
        <div className="mt-8">
          <div>{data.title}</div>
          <div>{data.date}</div>
          <div>{data.description}</div>
        </div>

        <div>
          <img src={data.poster} className="w-96 h-fit object-contain" alt="" />
        </div>
      </div>
    </div>
  );
}
