import React, { useEffect, useState } from "react";
import { getAllDocsSnap } from "../../utils/firebase.utils";
import PersonnelRequestCard from "../PersonnelRequest/PersonnelRequestCard";
import PersonnelRequestForm from "../PersonnelRequest/PersonnelRequestForm";
import PageModal from "../../components/PageModal";

export default function BudgetClearance() {
  const [requests, setRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeLink, setActiveLink] = useState("pending");
  const [viewMode, setViewMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const fetchRequests = async () => {
      await getAllDocsSnap("personnelRequests", (data) => {
        setRequests(data);
        setIsLoading(false);
      });
    };
    fetchRequests();
  }, []);

  const links = ["pending", "approved", "rejected"];

  const pendingItems = requests.filter((item) => {
    if (item.status === "pending budget clearance") {
      return item;
    }
  });

  const approvedItems = requests.filter((item) => {
    if (item.status === "approved") {
      return item;
    }
  });

  const rejectedItems = requests.filter((item) => {
    if (item.status === "rejected") {
      return item;
    }
  });

  const handleViewMode = (selected) => {
    setViewMode(!viewMode);

    if (viewMode === false) {
      setSelectedItem(selected);
    }
  };

  return (
    <div className="p-4">
      <div className="header">Budget Clearance Requests</div>

      <div className="flex items-center gap-4 mt-4">
        {links.map((link, index) => (
          <div
            key={index}
            className={`capitalize ${
              activeLink === link ? "active-pill" : "inactive-pill"
            }`}
            onClick={() => setActiveLink(link)}
          >
            {link}
          </div>
        ))}
      </div>

      {activeLink === "pending" && (
        <div className="mt-8">
          <div className="space-y-2">
            {pendingItems.map((item, index) => (
              <div
                key={index}
                className="card"
                onClick={() => handleViewMode(item)}
              >
                <PersonnelRequestCard data={item} />
              </div>
            ))}
          </div>
        </div>
      )}

      {activeLink === "approved" && (
        <div className="mt-8">
          <div className="space-y-2">
            {approvedItems.map((item, index) => (
              <div
                key={index}
                className="card"
                onClick={() => handleViewMode(item)}
              >
                <PersonnelRequestCard data={item} />
              </div>
            ))}
          </div>
        </div>
      )}

      {activeLink === "rejected" && (
        <div className="mt-8">
          <div className="space-y-2">
            {rejectedItems.map((item, index) => (
              <div
                key={index}
                className="card"
                onClick={() => handleViewMode(item)}
              >
                <PersonnelRequestCard data={item} />
              </div>
            ))}
          </div>
        </div>
      )}

      {viewMode && (
        <PageModal>
          <PersonnelRequestForm
            data={selectedItem}
            handleAddmode={handleViewMode}
          />
        </PageModal>
      )}
    </div>
  );
}
