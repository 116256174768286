import React from "react";

export default function AnnouncementCard({ data, setViewMode }) {
  return (
    <div className="card">
      <div className={data.status.toLowerCase()}>{data.status}</div>
      <div>{data.title}</div>
      <div>{data.date}</div>
      <div>{data.description}</div>
    </div>
  );
}
