import React from "react";

export default function RecruitmentCard({ data }) {
  return (
    <div>
      <div>Requested By:{data.requestedBy}</div>
      <div>Job Title: {data.jobTitle}</div>
      <div>Description: {data.description}</div>
      <div>
        Salary: Php
        {parseFloat(data.budgetedSalary).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </div>
      <div>Start Date: {data.startDate}</div>
      <div>Approved on: {data.approvedAt}</div>
      <div>Approved by: {data.approvedName}</div>
    </div>
  );
}
