import React from "react";
import InputBox from "../../components/InputBox";
import Button from "../../components/Button";
import Logo from "../../assets/leechiuBlueLogo.png";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loadUser } from "../../store/userSlice";
import { signInWithEmail, findUserDocbyId } from "../../utils/firebase.utils";
import LogoMyHr from '../../assets/LogoMyHr.png'

function Login() {
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const signIn = async () => {
    setError("");
    try {
      setIsLoading(true);
      const res = await signInWithEmail(formData.email, formData.password);
      if (res) {
        const { email, uid } = res.user;

        const { displayName, roles, department, leader } =
          await findUserDocbyId(res.user.uid);

        dispatch(
          loadUser({ email, displayName, uid, roles, department, leader })
        );
        navigate("/dashboard?splash=true");
      }
      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
      return;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    signIn();
    setError("");
    setFormData({});
  };

  return (
    <div className="bg-[url('https://images.unsplash.com/photo-1517048676732-d65bc937f952?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')] bg-cover bg-no-repeat">
      <div className="flex items-center justify-center h-screen bg-white bg-opacity-30">
        <div className="loginform bg-gray-200">
          <img src={LogoMyHr} alt="" className="w-24"/>
          {/* <img src={Logo} alt="leechiu logo" className="w-60" /> */}
          {/* <div className="text-blue-800 font-semibold mb-4">My HRMS Access</div> */}
          <div className="mt-4">
            <form
              className="flex flex-col items-center gap-2 "
              onSubmit={handleSubmit}
            >
              <InputBox
                type="email"
                placeholder="Email Address"
                label="Enter Email Address"
                name="email"
                onChange={handleChange}
                value={formData.email || ""}
                disabled={isLoading}
              />
              <InputBox
                type="password"
                placeholder="Password"
                label={"Enter Password"}
                name="password"
                onChange={handleChange}
                value={formData.password || ""}
                disabled={isLoading}
              />
              <Button
                type="submit"
                label={isLoading ? "Please wait.." : "Login"}
              />
            </form>
            {error && <div className="text-red-500">{error}</div>}
            <div className="text-center mt-4">
              <Link to="/resetpassword" className="text-blue-500">
                Forgot Password?
              </Link>
            </div>
            <div className="text-center">
              No Account Yet? Sign up
              <a href="/signup" className="text-blue-500">
                {" "}
                here
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
