import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { useMotionValue } from "framer-motion";
import { SlArrowLeftCircle } from "react-icons/sl";
import { SlArrowRightCircle } from "react-icons/sl";
import { getAllDocsSnap } from "../../utils/firebase.utils";

export default function UserAnnouncements() {
  const containerRef = useRef(null);
  const [dragging, setDragging] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [mobileWidth, setMobileWidth] = useState(380);
  const [announcements, setAnnouncements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      await getAllDocsSnap("announcements", (data) => {
        //filter unpublished status
        const publishedItems = data.filter((item) => {
          return item.status === "Published";
        });

        setPhotos(publishedItems);
        setIsLoading(false);
      });
    };
    fetchAnnouncements();
  }, []);

  //set auto slide every 2 seconds
  React.useEffect(() => {
    if (photos.length < 2) {
      setCurrentIndex(0);
      return;
    } else {
      const interval = setInterval(() => {
        if (currentIndex === photos.length - 1) {
          setCurrentIndex(0);
        } else {
          setCurrentIndex((prev) => prev + 1);
        }
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [currentIndex]);

  const dragX = useMotionValue(0);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  //   const photos = [
  //     {
  //       id: 1,
  //       url: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1723452295/elegho/ads/Screenshot_2024-08-12_164247_s1q2va.png",
  //     },
  //     {
  //       id: 2,
  //       url: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1723452296/elegho/ads/Screenshot_2024-08-12_164338_etxiea.png",
  //     },
  //     {
  //       id: 3,

  //       url: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1723452296/elegho/ads/Screenshot_2024-08-12_164318_mva6sw.png",
  //     },
  //   ];

  const onDragStart = () => {
    setDragging(true);
  };

  const onDragEnd = () => {
    setDragging(false);

    if (dragX.get() < -10) {
      if (currentIndex === photos.length - 1) {
        return;
      } else {
        setCurrentIndex((prev) => prev + 1);
      }
    } else if (dragX.get() > 10) {
      if (currentIndex === 0) {
        return;
      } else {
        setCurrentIndex((prev) => prev - 1);
      }
    }
  };

  return (
    <div className="w-full">
      <motion.div
        className="w-full  object-contain rounded-xl flex items-center overflow-hidden relative"
        ref={containerRef}
      >
        <motion.div
          className="flex items-center cursor-grab active:cursor-grabbing"
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          style={{
            x: dragX,
          }}
        >
          {photos.map((photo, index) => (
            <div
              key={index}
              className={`w-[380px] h-full rounded-xl shrink-0 bg-contain bg-center bg-no-repeat `}
              style={{
                transform: `translateX(-${currentIndex * 100}%)`,
                transition: "transform 0.5s ease",
                width: `${mobileWidth}px`,
              }}
            >
              <img
                src={photo.poster}
                alt="property"
                className=" w-full object-cover rounded-xl"
                // stop the drag event from bubbling up to the parent
                onDragStart={(e) => e.preventDefault()}
                onTouchStart={(e) => e.preventDefault()}
              />
            </div>
          ))}
        </motion.div>

        {/* create dot indicators */}
        {photos.length > 1 && (
          <div className="flex items-center justify-center gap-2 absolute bottom-4 p-2 rounded-xl bg-opacity-60 left-1/2 -translate-x-1/2 bg-black w-fit">
            {photos.map((photo, index) => (
              <div
                key={index}
                className={`h-2 w-2 rounded-full ${
                  index === currentIndex ? "bg-leechiuOrange" : "bg-white"
                }`}
              ></div>
            ))}
          </div>
        )}

        {/* create buttons for navigation */}
        {photos.length > 1 && (
          <>
            <div className="absolute top-1/2 left-0 transform -translate-y-1/2 max-sm:hidden ">
              <button
                className="rounded-full bg-opacity-50"
                onClick={() => {
                  if (currentIndex === 0) {
                    return;
                  } else {
                    setCurrentIndex((prev) => prev - 1);
                  }
                }}
              >
                <SlArrowLeftCircle className="text-white h-8 w-8 opacity-50 hover:opacity-100 ml-2" />
              </button>
            </div>
            <div className="absolute top-1/2 right-0 transform -translate-y-1/2 max-sm:hidden">
              <button
                className="rounded-full bg-opacity-50 "
                onClick={() => {
                  if (currentIndex === photos.length - 1) {
                    return;
                  } else {
                    setCurrentIndex((prev) => prev + 1);
                  }
                }}
              >
                <SlArrowRightCircle className="text-white h-8 w-8 opacity-50 hover:opacity-100 mr-2" />
              </button>
            </div>
          </>
        )}
      </motion.div>
    </div>
  );
}
