import React, { useState } from "react";
import { setDocData } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import OnlineApplications from "./OnlineApplications";

export default function RecruitmentDetails({ data, handleClose }) {
  const [formData, setFormData] = useState(data);
  const [isProcessing, setIsProcessing] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [activeLink, setActiveLink] = useState("online");

  const currentUser = useSelector((state) => state.userObject);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSaveDraft = async () => {
    setIsProcessing(true);
    setDisabled(true);
    const docObject = {
      ...data,
      ...formData,
      jobPostStatus: "unpublished",
      updatedby: currentUser.email,
      updatedName: currentUser.displayName,
      updatedAt: new Date().toLocaleString(),
      updateType: "draft job post",
    };
    const jobPostDoc = {
      jobPostStatus: "unpublished",
      jobTitle: formData.jobTitle || "",
      jobPostDescription: formData.jobPostDescription || "",
      videoQuestions: formData.videoQuestions || "",
      updatedby: currentUser.email,
      updatedName: currentUser.displayName,
      updatedAt: new Date().toLocaleString(),
      updateType: "draft job post",
    };
    await setDocData("personnelRequests", data.id, docObject);
    await setDocData("jobPosts", data.id, jobPostDoc);
    toast.success("Job post saved as draft");
    setIsProcessing(false);
    setDisabled(false);
  };

  const handlePublish = async () => {
    setIsProcessing(true);
    setDisabled(true);
    const docObject = {
      ...formData,
      jobPostStatus: "published",
      publishDate: new Date().toLocaleString(),
      updatedby: currentUser.email,
      updatedName: currentUser.displayName,
      updatedAt: new Date().toLocaleString(),
      updateType: "publish job post",
    };

    const jobPostDoc = {
      jobPostStatus: "published",
      jobTitle: formData.jobPostTitle,
      jobPostDescription: formData.jobPostDescription,
      videoQuestions: formData.videoQuestions,
      publishDate: new Date().toLocaleString(),
      updatedby: currentUser.email,
      updatedName: currentUser.displayName,
      updatedAt: new Date().toLocaleString(),
      updateType: "publish job post",
    };
    await setDocData("personnelRequests", data.id, docObject);
    await setDocData("jobPosts", data.id, jobPostDoc);
    setIsProcessing(false);
    setDisabled(false);
    toast.success("Job post successfully published");
  };

  const links = ["online", "others"];

  return (
    <div className="page relative flex flex-col lg:flex-row items-start gap-4">
      <button className="close-button" onClick={handleClose}>
        Close
      </button>
      <div className="w-full min-w-96">
        <div className="header">Recruitment Details</div>
        <div>{data.jobTitle}</div>
        <div>{data.budgetSalary}</div>
        <div>{data.startDate}</div>
        <div>{data.description}</div>
        <div>Budget Approved: {data.approvedAt}</div>
        <div>Approved by: {data.approvedName}</div>
        <div>Job Post Status: {data.jobPostStatus || "unpublished"} </div>

        <div className="mt-4 w-96">
          <div>Job Post Title</div>
          <textarea
            className="border p-2 rounded-lg border-gray-200 w-full"
            placeholder="Enter Job Title for Job Post"
            name="jobPostTitle"
            rows={"4"}
            id=""
            value={formData.jobPostTitle}
            onChange={handleChange}
            disabled={disabled}
          />
          <div>Job Description</div>
          <textarea
            className="border p-2 rounded-lg border-gray-200 w-full"
            placeholder="Enter Job Description for Job Post"
            name="jobPostDescription"
            rows={"4"}
            id=""
            value={formData.jobPostDescription}
            onChange={handleChange}
            disabled={disabled}
          />

          <div>Video Questions</div>
          <textarea
            className="border p-2 rounded-lg border-gray-200 w-full"
            placeholder="Enter Job Description for Job Post"
            name="videoQuestions"
            rows={"4"}
            id=""
            value={formData.videoQuestions}
            onChange={handleChange}
            disabled={disabled}
          />
        </div>

        <div className="flex items-center gap-2 mt-2">
          <button
            className="px-2 py-1 bg-gray-700 text-white"
            onClick={handleSaveDraft}
            disabled={isProcessing}
          >
            {isProcessing ? "Saving.." : "Save as Draft"}
          </button>
          <button className="blue-button" onClick={handlePublish}>
            {isProcessing ? "Saving" : "Publish Job Post"}
          </button>
        </div>
      </div>

      <div className="w-full">
        <div className="header">Job Applications</div>
        <div className="links-container mt-4">
          {links.map((link, index) => (
            <div
              className={`capitalize ${
                activeLink === link ? "activeLinkStyle" : "inactiveLinkStyle"
              }`}
              onClick={() => setActiveLink(link)}
            >
              {link}
            </div>
          ))}
        </div>

        {activeLink === "online" && <OnlineApplications data={data} />}
      </div>
    </div>
  );
}
