import React from "react";
import LeaveRequestsForApproval from "./LeaveRequestsForApproval";
import { useSelector } from "react-redux";
import BudgetClearance from "../pages/BudgetClearance/BudgetClearance";

export default function ApproverInboxMainPage() {
  const links = ["Leave Requests", "Expense Requests"];
  const [activeLink, setActiveLink] = React.useState("Leave Requests");
  const currentUser = useSelector((state) => state.userObject);

  return (
    <div className="text-gray-600 text-left">
      <div className="header">Approver's Inbox</div>

      <div className="mt-8 links-container">
        {links.map((link, index) => (
          <div
            key={index}
            className={
              activeLink === link ? "activeLinkStyle" : "inactiveLinkStyle"
            }
            onClick={() => setActiveLink(link)}
          >
            {link}
          </div>
        ))}

        {currentUser.roles.includes("finance-head") && (
          <div
            className={
              activeLink === "budget" ? "activeLinkStyle" : "inactiveLinkStyle"
            }
            onClick={() => setActiveLink("budget")}
          >
            Budget Clearances
          </div>
        )}
      </div>

      {activeLink === "Leave Requests" && <LeaveRequestsForApproval />}
      {activeLink === "budget" && <BudgetClearance />}
    </div>
  );
}
