import React, { useEffect, useState } from "react";
import PageModal from "../../components/PageModal";
import PersonnelRequestForm from "./PersonnelRequestForm";
import { queryAllDocsByFieldSnap } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import PersonnelRequestCard from "./PersonnelRequestCard";

export default function PersonnelRequest() {
  const [addMode, setAddMode] = useState(false);
  const [requests, setRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewMode, setViewMode] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchRequests = async () => {
      await queryAllDocsByFieldSnap(
        "personnelRequests",
        "requestedBy",
        currentUser.email,
        (data) => {
          setRequests(data);
          setIsLoading(false);
        }
      );
    };
    fetchRequests();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  console.log(requests);

  const handleViewMode = (selected) => {
    setViewMode(!viewMode);
    setSelectedData(selected);
  };

  return (
    <div>
      <div className="header">Personnel Request</div>
      <div className="mt-4">
        <button className="blue-button" onClick={handleAddMode}>
          Create a new request
        </button>
      </div>

      <div className="mt-4 space-y-2">
        {requests.map((item, index) => (
          <div
            key={index}
            className="card"
            onClick={() => handleViewMode(item)}
          >
            <PersonnelRequestCard data={item} />
          </div>
        ))}
      </div>

      {addMode && (
        <PageModal>
          <PersonnelRequestForm handleAddmode={handleAddMode} />
        </PageModal>
      )}

      {viewMode && (
        <PageModal>
          <PersonnelRequestForm
            handleAddmode={handleViewMode}
            data={selectedData}
          />
        </PageModal>
      )}
    </div>
  );
}
