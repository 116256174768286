import toast from "react-hot-toast";
import Button from "../../components/Button";
import React from "react";
import { useState } from "react";
import { createNewDocument, getAllDocsSnap } from "../../utils/firebase.utils";

function Appraisal() {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [newScoreCardCreated, setNewScoreCardCreated] = useState(false);

  const handleCreateNewScoreCard = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleCreate = () => {
    toast.success("New score card created successfully");
    setNewScoreCardCreated(true);
  };

  return (
    <div className="p-4 text-left">
      <div className="header">Performance Management</div>
      <div>This feature is under development</div>

      {/* <div>
        <div className="mt-8">
          <Button
            label={showDatePicker ? "Cancel" : "Create New Score Card"}
            onClick={handleCreateNewScoreCard}
          />
          {showDatePicker && (
            <div className="flex flex-col gap-2 mt-4">
              <label>Select Date</label>
              <input
                className="px-4 py-2 rounded-xl bg-gray-100 w-60"
                type="date"
              />
              <Button label={"Create"} onClick={handleCreate} />
            </div>
          )}
        </div>
        {newScoreCardCreated && (
          <div className="text-orange-600 font-semibold mt-4">
            Select below for Draft Scorecards
          </div>
        )}
      </div> */}
    </div>
  );
}

export default Appraisal;
