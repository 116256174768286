import React, { useEffect } from "react";
import { useState } from "react";
import { getAllDocsSnap } from "../../utils/firebase.utils";
import NewAnnouncementForm from "./NewAnnouncementForm";
import PageModal from "../../components/PageModal";
import AnnouncementCard from "./AnnouncementCard";
import AnnouncementDetails from "./AnnouncementDetails";

export default function Announcements() {
  const [addMode, setAddMode] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewMode, setViewMode] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState({});

  useEffect(() => {
    const fetchAnnouncements = async () => {
      await getAllDocsSnap("announcements", (data) => {
        setAnnouncements(data);
        setIsLoading(false);
      });
    };
    fetchAnnouncements();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleAdd = () => {
    setAddMode(true);
  };

  const handleViewMode = (announcement) => {
    setViewMode(true);
    setSelectedAnnouncement(announcement);
  };

  return (
    <div>
      <div className="header">Announcements</div>
      <div className="mt-4">
        <button className="blue-button" onClick={handleAdd}>
          Create a new announcement
        </button>
      </div>

      <div className="space-y-4 mt-4">
        {announcements.map((announcement) => (
          <div
            key={announcement.id}
            className="announcement-card"
            onClick={() => handleViewMode(announcement)}
          >
            <AnnouncementCard data={announcement} setViewMode={setViewMode} />
          </div>
        ))}
      </div>

      {addMode && (
        <PageModal>
          <NewAnnouncementForm setAddMode={setAddMode} />
        </PageModal>
      )}

      {viewMode && (
        <PageModal>
          <AnnouncementDetails
            data={selectedAnnouncement}
            setViewMode={setViewMode}
          />
        </PageModal>
      )}
    </div>
  );
}
