import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDocDataSnap,
  setDocData,
  storage,
  deleteDocById,
} from "../../utils/firebase.utils";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import SelectBox from "../../components/SelectBox";

export default function UserRequestDetails() {
  const { id } = useParams();
  const [request, setRequest] = useState({});
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const currentUser = useSelector((state) => state.userObject);
  const [isProcessing, setIsProcessing] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [status, setStatus] = useState("");
  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState("null");
  
  const navigate = useNavigate();

  useEffect(() => {
    const getRequest = async () => {
      const request = await getDocDataSnap("requests", id, (request) => {
        setStatus(request.status);
        setFile(request.file);
        setRequest(request);
        setComments(request.comments || []);
        setLoading(false);
      });
    };
    getRequest();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleAddComment = async (e) => {
    setIsProcessing(true);

    const newComments = [
      ...comments,
      {
        comment,
        commentBy: currentUser.displayName,
        commentByEmail: currentUser.email,
        date: new Date().toLocaleString(),
      },
    ];

    await setDocData("requests", id, { comments: newComments });
    setComments(newComments);
    setComment("");
    setIsProcessing(false);
    toast.success("Comment added successfully");
  };

  const handleEdit = async () => {
    setEditMode(!editMode);

    if (editMode) {
      const docObject = {
        ...request,
        status: status,
        updatedBy: currentUser.displayName,
        updatedByEmail: currentUser.email,
        updatedAt: new Date().toLocaleString(),
        // file: file ? file : request.file,
      };
      await setDocData("requests", id, docObject);
      handleSendEmailNotification();
      toast.success("Request updated successfully");
    }
  };

  const handleSendEmailNotification = async () => {
    const recipients = ["aidi.tia@leechiu.com", "maricor.sabangan@leechiu.com"];
    const docObject = {
      requestor: recipients,
      recipientName: request.requestedBy,
      recipientEmail: request.requestedByEmail,
      subject: "HR Request Status Updated" + " - " + request.requestType,
      message: `Your HR request has been updated to ${status}. Please check the HR Requests page for more details.`,
      link: "https://myhr.leechiu.app/requests",
    };

    const sendEmail = async () => {
      await fetch("https://hrms-emailerv1.vercel.app/api/notifications", {
        method: "POST",
        body: JSON.stringify(docObject),
      });

      console.log("Email sent");
    };
    sendEmail();
  };

  const statusOptions = [
    { value: "pending", label: "Pending" },
    { value: "approved", label: "Approved" },
    { value: "rejected", label: "Rejected" },
  ];

  const handleSelect = async (selectedOption) => {
    setStatus(selectedOption.value);
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `files/requests/${id}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          await setDocData("requests", id, { file: downloadURL });
          toast.success("File uploaded successfully");
        });
      }
    );
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this request?")) {
      await deleteDocById("requests", id);
      toast.success("Request deleted successfully");
    }
    navigate("/requests");
  };

  return (
    <div className=" relative">
      <div className="header">Request Details</div>
      {request.requestedByEmail === currentUser.email &&
        request.status === "pending" && (
          <button onClick={handleDelete} className="close-button">
            Delete
          </button>
        )}

      {/* <div className="close-button">
        <button onClick={handleDelete}>Delete</button>
      </div> */}

      <div className="mt-8 bubble">
        <div className="flex items-center justify-between max-w-[350px] border-b border-gray-200 py-2">
          <div className="font-bold">Status</div>
          <div className="capitalize">{request.status}</div>
        </div>

        <div className="flex items-center justify-between max-w-[350px] border-b border-gray-200 py-2">
          <div className="font-bold">Requested by</div>
          <div>{request.requestedBy}</div>
        </div>

        <div className="flex items-center justify-between max-w-[350px] border-b border-gray-200 py-2">
          <div className="font-bold">Type of Request</div>
          <div>
            {request.requestType}

            {request.requestType === "Others" && ` - ${request.description}`}
          </div>
        </div>

        <div className="flex items-center justify-between max-w-[350px] border-b border-gray-200 py-2">
          <div className="font-bold">Purpose</div>
          <div>{request.purpose}</div>
        </div>

        <div className="flex items-center justify-between max-w-[350px] border-b border-gray-200 py-2">
          <div className="font-bold">Date Required</div>
          <div>{request.dateRequired}</div>
        </div>

        <div className="flex items-center justify-between max-w-[350px] border-b border-gray-200 py-2">
          <div className="font-bold">Date Requested</div>
          <div>{request.dateRequested}</div>
        </div>

        <div className="flex items-center justify-between max-w-[350px] border-b border-gray-200 py-2">
          <div className="font-bold">File Uploaded</div>
          {!request.file || request.file === "null" ? (
            <div>No file uploaded</div>
          ) : (
            <a href={request.file} target="_blank" rel="noreferrer">
              View File
            </a>
          )}
        </div>

        <div>
          {currentUser.roles.includes("hr maker") && (
            <div className="mt-4">
              <div>
                <button className="blue-button" onClick={handleEdit}>
                  {editMode ? "Save" : "Edit"}
                </button>

                <div className="mt-4 w-60">
                  <SelectBox
                    disabled={!editMode}
                    name="status"
                    label="Status"
                    placeholder={"Select Status"}
                    options={statusOptions}
                    handleSelect={handleSelect}
                    value={statusOptions.find(
                      (option) => option.value === status
                    )}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {editMode && (
          <div className="mt-4">
            <div className="">Uploaded File</div>
            <input type="file" onChange={handleUpload} />
            {progress > 0 && <progress value={progress} max="100" />}
          </div>
        )}
      </div>

      <div className="mt-8">
        <div className="header">Comments</div>
        <div className="mt-4 space-y-2">
          {comments.map((comment, index) => (
            <div key={index} className="border border-gray-200 p-2 bubble">
              <div className="font-bold">{comment.commentBy}</div>
              <div>{comment.comment}</div>
              <div>{comment.date}</div>
            </div>
          ))}

          {comments.length < 1 && <div>No comments yet</div>}
        </div>
      </div>

      <div className="mt-8">
        <textarea
          name="comment"
          id=""
          rows={3}
          className="w-full rounded-lg px-2 py-1"
          placeholder="Add Comment Here"
          onChange={(e) => setComment(e.target.value)}
          value={comment}
        ></textarea>

        <button
          className="blue-button mt-4 disabled:opacity-50"
          onClick={handleAddComment}
          disabled={isProcessing}
        >
          {isProcessing ? "Saving Comment" : "Add Comment"}
        </button>
      </div>

      <div className="mt-8">
        {currentUser.roles.includes("hr maker") && <div></div>}
      </div>
    </div>
  );
}
