import React from "react";

function InputBox({ onChange, type, placeholder, label, ...otherprops }) {
  return (
    <>
      <div className="flex flex-col items-start w-fit">
        <label className="text-xs font-semibold text-gray-800">{label}</label>
        <input
          className="text-xs border border-solid border-gray-300 min-w-60  w-full rounded-md px-2 py-1 focus:outline-none focus:border-blue-500 transition duration-300 ease-in-out disabled:bg-white bg-gray-100  "
          type={type}
          placeholder={placeholder}
          {...otherprops}
          onChange={onChange}
        />
      </div>
    </>
  );
}

export default InputBox;
