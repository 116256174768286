import React from "react";

export default function Training() {
  return (
    <div>
      <div className="header">Training and Development</div>
      <div>This feature is under development.</div>
    </div>
  );
}
