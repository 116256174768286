import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/login/Login";
import Signup from "./pages/login/Signup";
import { useSelector } from "react-redux";
import ResetPassword from "./pages/login/ResetPassword";
import Main from "./Main";
import Payroll from "./pages/Payroll/Payroll";
import Employees from "./pages/Employees/Employees";
import EmployeeDetails from "./pages/Employees/EmployeeDetails";
import DraftPayroll from "./pages/Payroll/DraftPayroll";
import PayrollViewMode from "./pages/Payroll/PayrollViewMode";
import ExcelToJsonConverter from "./pages/Converter/ExcelConverter";
import Compensation from "./pages/Compensation/Compensation";
import UserAccess from "./pages/AccessManagement/UserAccess";
import Appraisal from "./pages/PerformanceManagement/Appraisal";
import UserRequest from "./pages/UserPages/UserRequest";
import UserRequestDetails from "./pages/UserPages/UserRequestDetails";
import HRRequests from "./pages/HRpages/HRRequests";
import LeavesMainPage from "./pages/LeaveManagement/LeavesMainPage";
import UserApprovers from "./Devtools/UserApprovers";
import ApproverInboxMainPage from "./ApproverInbox/ApproverInboxMainPage";
import Training from "./pages/Training/Training";
import Announcements from "./pages/Announcements/Announcements";
import PersonnelRequest from "./pages/PersonnelRequest/PersonnelRequest";
import BudgetClearance from "./pages/BudgetClearance/BudgetClearance";
import Recruitment from "./pages/Recruitment/Recruitment";

// this is a test branch

//this is another edit

function App() {
  const currentUser = useSelector((state) => state.userObject);
  if (
    !currentUser?.email ||
    currentUser?.email === null ||
    currentUser?.email === "" ||
    currentUser?.email === undefined ||
    currentUser === undefined ||
    currentUser === null
  ) {
    return (
      <div className="App">
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="resetpassword" element={<ResetPassword />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </Router>
      </div>
    );
  }

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Main />}>
            <Route index element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/approverInbox" element={<ApproverInboxMainPage />} />
            <Route path="/budgetClearance" element={<BudgetClearance />} />
            <Route path="/announcements" element={<Announcements />} />
            <Route path="/devtools/userapprovers" element={<UserApprovers />} />
            <Route path="/payroll" element={<Payroll />} />
            <Route path="/leaves" element={<LeavesMainPage />} />
            <Route path="/performance" element={<Dashboard />} />
            <Route path="/employees" element={<Employees />} />
            <Route path="/employees/:id" element={<EmployeeDetails />} />
            <Route path="/reservations" element={<Dashboard />} />
            <Route path="/requests" element={<UserRequest />} />
            <Route path="/request/:id" element={<UserRequestDetails />} />
            <Route path="/hr-requests" element={<HRRequests />} />
            <Route path="/personnelRequest" element={<PersonnelRequest />} />
            <Route path="/profile" element={<Dashboard />} />
            <Route path="/recruitment" element={<Recruitment />} />
            <Route path="/settings" element={<Dashboard />} />
            <Route path="/training" element={<Training />} />
            <Route path="/draftpayroll/:id" element={<DraftPayroll />} />
            <Route path="/payrollviewmode/:id" element={<PayrollViewMode />} />
            <Route path="/converter" element={<ExcelToJsonConverter />} />
            <Route path="/compensation" element={<Compensation />} />
            <Route path="/userAccess" element={<UserAccess />} />
            <Route path="/appraisal" element={<Appraisal />} />
            <Route
              path="*"
              element={<h1>This page still under construction</h1>}
            />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
